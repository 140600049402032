const es = require('apexcharts/dist/locales/es.json')

export const opcionesGraficaEstadisticas = {
  chart: {
    height: 298,
    width: 679,
    type: 'heatmap',
    toolbar: {
      show: false
    },
    defaultLocale: 'es',
    locales: [es]
  },
  dataLabels: {
    enabled: false
  },
  tooltip: {
    y: {
      formatter: undefined,
      title: {
        formatter: (seriesName, series) => {
          return `${series.dataPointIndex + 1} ${seriesName}:`
        }
      }
    }
  },
  states: {
    hover: {
      filter: {
        type: 'none'
      }
    }
  },
  colors: ['#219EFC'],
  fill: {
    opacity: 1
  },
  // markers: {
  //   size: 4,
  //   colors: ['rgba(4, 128, 218, .5)']
  // },
  grid: {
    padding: {
      right: 4
    }
  },
  xaxis: {
    tickAmount: 32
  },
  stroke: {
    width: 1
  },
  plotOptions: {
    heatmap: {
      shadeIntensity: 0.5,
      radius: 3,
      useFillColorAsStroke: false,
      colorScale: {
        ranges: [
          {
            from: 0,
            to: 20,
            color: '#C8C8C8',
            name: '0-20'
          },
          {
            from: 20,
            to: 50,
            color: '#A1E7ED',
            name: '20-50'
          },
          {
            from: 50,
            to: 100,
            color: '#5FABDF',
            name: '50-100'
          },
          {
            from: 100,
            to: 1000000,
            color: '#0683DA',
            name: '+100'
          }
        ]
      }
    }
  }
  // yaxis: {
  //   show: false
  // },
  // plotOptions: {
  //   heatmap: {
  //     shadeIntensity: 0.5,
  //     radius: 0,
  //     useFillColorAsStroke: true
  //   }
  // }
}

export const opcionesGraficaPie = {
  series: [44, 55, 13, 0],
  chartOptions: {
    chart: {
      width: 480,
      type: 'pie'
    },
    labels: ['No vistas', 'En aprendizaje', 'Masterizadas', 'Flashcards Totales'],
    fill: {
      colors: ['#45E4D1', '#4598E4', '#2541D3', '#25AAD3']
    },
    colors: ['#45E4D1', '#4598E4', '#2541D3', '#25AAD3'],
    tooltip: {
      enabled: false
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 0
    },
    markers: {
      fillColors: ['#45E4D1', '#4598E4', '#2541D3', '#25AAD3']
    },
    legend: {
      show: true,
      position: 'right',
      labels: {
        colors: ['#45E4D1', '#4598E4', '#2541D3'],
        useSeriesColors: false
      },
      formatter: function (seriesName, opts) {
        if (opts.seriesIndex === 3) {
          return [seriesName, ' ', opts.w.globals.series.reduce((a, b) => a + b, 0)]
        }
        return [seriesName, ' ', opts.w.globals.series[opts.seriesIndex]]
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: '100%',
          height: 300
        },
        legend: {
          position: 'bottom',
          labels: {
            colors: ['#4598E4', '#2541D3', '#45E4D1', '#21C2D1', '#25AAD3']
          }
        }
      }
    }]
  }
}
