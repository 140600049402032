<template>
  <v-container class="d-flex justify-center">
    <v-col
      cols="12"
      md="11"
    >
      <slot />
    </v-col>
  </v-container>
</template>

<script>
export default {
  name: 'UnitsAreasSpecialities'
}
</script>
