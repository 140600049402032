<template>
  <UnitsFrame>
    <div class="main" :class="$vuetify.breakpoint.smAndDown ? 'd-flex flex-column': 'px-2'">
      <p class="section__text--title mt-5">Calendario de avance</p>
      <v-card v-if="$vuetify.breakpoint.mdAndUp" class="main__card mx-auto">
        <apexchart
          type="heatmap"
          :options="grafica.options"
          :series="grafica.series"
          ref="grafica"
          class="mx-15 my-1"
        ></apexchart>
      </v-card>
      <div
        class="main__statistics mx-auto"
        :class="$vuetify.breakpoint.smAndDown ? 'flex-column pa-0': 'mt-5'"
      >
        <div
          v-for="(card, index) in informationCard"
          :key="index"
          class="main__card-statistics d-flex justify-center flex-column align-center avoid_selection"
        >
          <p class="general__text--subtitle mx-5 text-center">{{ card.descripcion }}</p>
          <div class="main__circle pa-0 ma-0" :class="card.clase"></div>
        </div>
      </div>
      <p class="section__text--title mt-5 pl-3" v-if="$vuetify.breakpoint.mdAndUp">Datos extras</p>
      <div
        class="d-flex justify-space-between align-center main__footer mx-auto"
        :class="{'flex-column order-4': $vuetify.breakpoint.smAndDown}"
      >
        <p class="section__text--title mt-5 pl-3 w-100" v-if="!$vuetify.breakpoint.mdAndUp">Datos extras</p>
        <v-card
          class="main__extra d-flex align-center justify-center mb-5"
          :style="$vuetify.breakpoint.smAndUp ? 'height: 280px;': ''"
          :class="$vuetify.breakpoint.smAndUp ? 'mr-5': 'mr-0'"
          :width="$vuetify.breakpoint.smAndDown ? '100%': ''"
        >
          <apexchart
            type="pie"
            class="ma-auto"
            :class="$vuetify.breakpoint.smAndDown ? 'my-10': ''"
            height="200"
            style="width: 90%;"
            :options="graficaPie.chartOptions"
            :series="graficaPie.series"
          />
        </v-card>
        <v-card
          v-if="$vuetify.breakpoint.mdAndUp"
          class="main__racha d-flex flex-column justify-center align-center mb-5"
        >
          <v-img :src="$vuetify.breakpoint.mdAndUp ? require('@/assets/img/racha-frame.svg'): require('@/assets/img/racha-frame-dark.svg')" max-height="147" width="183" class="d-flex align-center justify-center">
            <p class="text-center main__racha-number avoid_selection my-auto">{{ racha }}</p>
          </v-img>
          <p class="pa-0 ma-0 mx-5 main__racha-description avoid_selection" :class="$vuetify.breakpoint.mdAndUp ? 'pt-3': 'px-10 pt-5'">Racha de días haciendo flashcards</p>
        </v-card>
      </div>
      <v-card
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="main__racha d-flex flex-column justify-center align-center mb-5"
      >
        <v-img :src="$vuetify.breakpoint.mdAndUp ? require('@/assets/img/racha-frame.svg'): require('@/assets/img/racha-frame-dark.svg')" max-height="147" width="183" class="d-flex align-center justify-center">
          <p class="text-center main__racha-number avoid_selection my-auto">{{ racha }}</p>
        </v-img>
        <p class="pa-0 ma-0 mx-5 main__racha-description avoid_selection" :class="$vuetify.breakpoint.mdAndUp ? 'pt-3': 'px-10 pt-5'">Racha de días haciendo flashcards</p>
      </v-card>
    </div>
  </UnitsFrame>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { opcionesGraficaEstadisticas, opcionesGraficaPie } from '@/utils/charts'
import UnitsFrame from '@/components/units/UnitsFrame'
import moment from 'moment'
import axios from 'axios'

export default {
  data () {
    return {
      informationCard: [
        {
          type: 'promedio',
          descripcion: ''
        },
        {
          type: 'maximo',
          descripcion: ''
        },
        {
          type: 'minimo',
          descripcion: ''
        }
      ],
      grafica: {
        series: [],
        options: opcionesGraficaEstadisticas
      },
      graficaPieData: [],
      graficaPie: opcionesGraficaPie
    }
  },
  components: {
    UnitsFrame
  },
  computed: {
    ...mapState('advance', ['quantityOfFlashcardsByDay', 'statisticsOfFlashcardsByDay']),
    ...mapState('flashcard', ['flashcardUnits']),
    ...mapState('profile', ['user']),
    racha () {
      if (this.user) {
        return this.user.dias_racha
      } else {
        return 0
      }
    }
  },
  methods: {
    assignColor (item, type) {
      if (type === 'maximo') {
        if (item < 20) {
          return 'main__circle--bajo'
        } else if (item > 20 && item < 100) {
          return 'main__circle--medio'
        } else {
          return 'main__circle--alto'
        }
      } else {
        if (item < 20) {
          return 'main__circle--bajo'
        } else if (item > 20 && item < 50) {
          return 'main__circle--medio'
        } else {
          return 'main__circle--alto'
        }
      }
    },
    ...mapActions('flashcard', ['fetchFlashcardsUnits']),
    ...mapActions('advance', ['fetchQuantityOfFlashcardsByDay', 'fetchStatisticsOfFlashcardsByDay']),
    assignStats () {
      for (const item of this.informationCard) {
        if (item.type === 'promedio') {
          const numberFcs = parseInt(this.statisticsOfFlashcardsByDay.promedio_flashcards_por_dia)
          item.descripcion = `Tu promedio de flashcards diarias: ${numberFcs || 0}`
          item.clase = this.assignColor(numberFcs, 'promedio')
        } else if (item.type === 'maximo') {
          const numberFcs = parseInt(this.statisticsOfFlashcardsByDay.maximo_flashcards_por_dia)
          item.descripcion = `Tu cantidad máxima de flashcards diarias: ${numberFcs || 0}`
          item.clase = this.assignColor(numberFcs, 'maximo')
        } else {
          const numberFcs = parseInt(this.statisticsOfFlashcardsByDay.minimo_flashcards_por_dia)
          item.descripcion = `Tu cantidad mínima de flashcards semanal: ${numberFcs || 0}`
          item.clase = this.assignColor(numberFcs, 'minimo')
        }
      }
    },
    async fetchPieChartData () {
      const response = await axios.get('api/flashcards/flashcards/estadisticas_por_fase/')
      this.graficaPieData = response.data
    },
    getFcByDay (dataByMonth) {
      const fcbyday = []
      for (const i of [...Array(31).keys()]) {
        if (dataByMonth.map(item => item.dia).includes(i + 1)) {
          fcbyday.push(dataByMonth.find(item => item.dia === i + 1).flashcards_por_dia)
        } else {
          fcbyday.push(0)
        }
      }
      return fcbyday
      // .map(item => { item.dia, item.flashcards_por_dia })
    },
    formattingDataForHeatmap () {
      moment.locale('es')
      this.quantityOfFlashcardsByDay.forEach(element => {
        element.mes = moment(element.fecha_respuesta__date).format('MMMM').toUpperCase().slice(0, 3)
        const ll = element.fecha_respuesta__date
        element.dia = parseInt(ll.substring(ll.length - 2, ll.length))
      })
      // const months = [...new Set(this.quantityOfFlashcardsByDay.map(item => item.mes))]
      const months = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SET', 'OCT', 'NOV', 'DIC']
      const graficaSeries = []
      for (const month of months) {
        graficaSeries.push({
          name: month,
          data: this.getFcByDay(this.quantityOfFlashcardsByDay.filter(item => item.mes === month))
        })
      }
      return graficaSeries
    }
  },
  async created () {
    await this.fetchQuantityOfFlashcardsByDay()
    await this.fetchStatisticsOfFlashcardsByDay()
    await this.assignStats()
    await this.fetchFlashcardsUnits()
    await this.fetchPieChartData()
    this.graficaPie.series = [this.graficaPieData.flashcards_no_vistas, this.graficaPieData.flashcards_aprendizaje, this.graficaPieData.flashcards_masterizadas, 0]
    // this.graficaPie.chartOptions.labels = ['En aprendizaje', 'Masterizadas', 'No vistas', 'Flashcards Respondidas', 'Flashcards Totales']
    this.graficaPie.chartOptions.colors = ['#45E4D1', '#4598E4', '#2541D3', '#25AAD3']
    this.grafica.series = this.formattingDataForHeatmap()
  }
}
</script>

<style lang="scss" scoped>
.main {
  &__card {
    width: 100%;
    // max-height: 369px;
    overflow: auto;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15) !important;
    border-radius: 10px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__card-statistics {
    width: 100%;
    height: 154px;
    background: #FFFFFF;
    // border: 1px solid #E5E5E5;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15) !important;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    padding: 0 !important;
  }
  &__statistics {
    width: 100% !important;
    order: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    column-gap: 20px;
  }
  &__footer {
    width: 100%;
    order: 0;
  }
  &__extra {
    // width: 584px;
    width: 67.5%;
    // height: 280px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15) !important;
    border-radius: 10px;
  }
  &__racha {
    // width: 283px;
    width: 32.5%;
    height: 280px;
    background: linear-gradient(90deg, #1FC1D0 0%, #0583DA 100%);
    border: 1px solid #E5E5E5;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15) !important;
    border-radius: 10px;
  }
  &__racha-description {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
  }
  &__racha-number {
    font-weight: 600;
    font-size: 60px;
    line-height: 90px;
    text-align: center;
    color: #000000;
  }
  &__circle {
    background: lightblue;
    position: absolute;
    top: 120.21px;
    border-radius: 50%;
    width: 382.68px;
    height: 382.68px;
    &--medio {
      background: linear-gradient(90deg, #FFA51B 21.33%, #FFD18B 76.8%);
    }
    &--bajo {
      background: linear-gradient(90deg, #DB0707 21.33%, #F77272 76.8%);
    }
    &--alto {
      background: linear-gradient(90deg, #21C2D1 21.33%, #9AE7EE 76.8%);
    }
  }
}
@media (max-width: 960px) {
  .main {
    &__card {
      width: 100%;
    }
    &__racha {
      width: 100%;
      height: 311px;
      background: #FFF;
    }
    &__racha-description {
      color: #000;
    }
    &__racha-number {
      color: #FFF;
    }
    &__statistics {
      width: 100%;
    }
    &__card-statistics {
      width: 100%;
      margin-bottom: 20.6px;
    }
    &__footer {
      width: 100%;
    }
  }
}
@media (max-width: 600px) {
  .main {
    &__statistics {
      width: 92%;
      order: 1;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(3, 1fr);
      column-gap: 20px;
    }
  }
}
</style>
